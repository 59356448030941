<template>
  <v-row justify="center">
    <v-col
      cols="10"
      md="10"
      class="text-center"
    >
      <h3 class="mb-4">
        Your Orders
      </h3>
      <order-inspect />
      <e-info-card
        title="Manage Orders"
        sub-title="Review the status of your orders."
      >
        <div class="d-flex align-center justify-space-between pr-3">
          <v-card-title>Manage Order</v-card-title>

          <div>
            <v-menu
              left
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="() => {}">
                  <v-list-item-title>
                    Get Help
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <v-card-text />
      </e-info-card>
      <p />
    </v-col>

    <v-col
      cols="10"
      md="10"
    >
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                ID
              </th>
              <th class="text-left">
                Date & Time
              </th>
              <th class="text-left">
                Price
              </th>
              <th class="text-left">
                Status
              </th>
              <th class="text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in registrantOrders"
              :key="item.name"
            >
              <td>{{ item._id }}</td>
              <td class="text-no-wrap">
                {{
                  item.timestamp_created
                    ? formatDate(item.timestamp_created)
                    : 'Unknown'
                }}
              </td>
              <td>
                <h6 class="text-success">
                  ${{ formatCurrency(item.final_price) }}
                </h6>
              </td>
              <td>
                <div class="d-flex align-center">
                  <template v-if="item.order_status === 'Complete'">
                    <v-sheet
                      width="20"
                      height="20"
                      class="success lighten-4 rounded-circle  d-flex  align-center justify-center mr-2"
                    >
                      <v-icon class="success--text text-caption">
                        mdi-check
                      </v-icon>
                    </v-sheet>
                    <span>ACTIVE</span>
                  </template>
                  <template v-if="item.order_status === 'Pending'">
                    <v-sheet
                      width="20"
                      height="20"
                      class="primary lighten-4 rounded-circle  d-flex  align-center justify-center mr-2"
                    >
                      <v-icon class="primary--text text-caption">
                        mdi-progress-clock
                      </v-icon>
                    </v-sheet>
                    <span>IN PROGRESS</span>
                  </template>
                  <template v-if="item.order_status === 'Rejected'">
                    <v-sheet
                      width="20"
                      height="20"
                      class="danger lighten-4 rounded-circle  d-flex  align-center justify-center mr-2"
                    >
                      <v-icon class="danger--text text-caption">
                        mdi-progress-close
                      </v-icon>
                    </v-sheet>
                    <span>{{ item.status }}</span>
                  </template>
                </div>
              </td>
              <td>
                <v-menu
                  left
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      v-on="on"
                    >
                      <v-icon class="text-primary">
                        mdi-dots-horizontal
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="openOrder(item)">
                      <v-list-item-title>
                        View Order
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="() => {}">
                      <v-list-item-title>
                        Refund
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="() => {}">
                      <v-list-item-title>
                        Delete
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>
<script>
  import { mapGetters, mapActions, mapMutations } from 'vuex'
  import moment from 'moment'
  import EInfoCard from '@/components/card/BasicInfoCard'
  import OrderInspect from './OrderInspect'
  import { utilities } from '../../../mixins/utility'
  export default {
    name: 'RegistrantOrders',
    components: {
      EInfoCard,
      OrderInspect,
    },
    mixins: [utilities],
    methods: {
      ...mapMutations(['OPEN_SPECIFIC_ORDER_DIALOG', 'SET_SPECIFIC_ORDER']),
      ...mapActions(['getAllRegistrantOrders']),
      formatDate (timestamp) {
        return moment(timestamp, 'X').format('LLL')
      },
      openOrder (selectedOrder) {
        this.SET_SPECIFIC_ORDER(selectedOrder)
        this.OPEN_SPECIFIC_ORDER_DIALOG()
      },
    },
    data () {
      return {
        orders: [],
        openOrderDialog: false,
      }
    },
    mounted () {
      this.getAllRegistrantOrders().then(() => {
        this.orders = this.registrantOrders
      })
    },
    computed: {
      ...mapGetters(['registrantOrders']),
    },
  }
</script>

<style></style>
