<template>
  <div class="health-box">
    <v-dialog
      v-model="specificHealthStatementDialog"
      width="800"
    >
      <v-card>
        <v-card-title class="text-h5">
          Health Statment {{ specificOrderSelected._id }}
        </v-card-title>
        <v-divider />

        <v-col>
          <div
            v-for="stringKey in getRawKeys()"
            :key="stringKey"
            class="center-space"
          >
            <span
              v-if="
                parseValue(specificOrderSelected.health_statement[stringKey])
                  .length > 0
              "
            >{{ parseKey(stringKey) }} -
            </span>
            {{ parseValue(specificOrderSelected.health_statement[stringKey]) }}
          </div>
        </v-col>

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click.stop="CLOSE_SPECIFIC_HEALTH_STATEMENT_DIALOG()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import { mapGetters, mapMutations } from 'vuex'
  import * as _ from 'lodash'

  export default {
    name: 'HealthStatementDisplay',
    components: {},
    data () {
      return {}
    },
    computed: {
      ...mapGetters(['specificHealthStatementDialog', 'specificOrderSelected']),
    },
    mounted () {},
    methods: {
      ...mapMutations(['CLOSE_SPECIFIC_HEALTH_STATEMENT_DIALOG']),
      getRawKeys () {
        if (this.specificOrderSelected?.health_statement) {
          return Object.keys(this.specificOrderSelected?.health_statement)
        }

        return {}
      },

      parseKey (rawKey) {
        return _.startCase(rawKey.replace('_', ' '))
      },
      parseValue (rawValue) {
        if (typeof rawValue === 'boolean') {
          return rawValue === true ? 'Yes' : 'No'
        }
        return rawValue
      },
    },
  }
</script>

<style></style>
