<template>
  <div class="text-center">
    <v-dialog
      v-model="specificOrderDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Order {{ specificOrderSelected._id }}
        </v-card-title>
        <v-col>
          <v-chip
            v-if="specificOrderSelected.order_status === 'Pending'"
            close-icon="mdi-close-outline"
            color="gray"
          >
            Pending
          </v-chip>
          <v-chip
            v-if="specificOrderSelected.order_status === 'Complete'"
            close-icon="mdi-close-outline"
            color="green"
          >
            Complete
          </v-chip>

          <v-chip
            v-if="specificOrderSelected.order_status === 'Rejected'"
            close-icon="mdi-close-outline"
            color="red"
          >
            Rejected
          </v-chip>
        </v-col>

        <v-card-title class="text-h5">
          Products
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in displayableProducts"
                :key="item.id"
              >
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <td
                      v-bind="attrs"
                      class="text-left"
                      v-on="on"
                    >
                      {{ item.name }}
                    </td>
                    <td
                      v-bind="attrs"
                      class="text-left"
                      v-on="on"
                    >
                      ${{ (item.price / 100).toFixed(2) }}
                    </td>
                  </template>
                  <div class="configuration-tooltip">
                    <h4>Prefrences:</h4>

                    <span
                      v-if="!item.orderEntry"
                    >No preferences set for product</span>
                    <span
                      v-for="entry in item.orderEntry"
                      :key="entry.id"
                    >{{ entry.fieldName }} - {{ entry.value }}</span>
                  </div>
                </v-tooltip>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-divider />

        <v-card-title class="text-h6">
          Total ${{ (specificOrderSelected.final_price / 100).toFixed(2) }}
        </v-card-title>
        <v-card-actions>
          <v-btn
            v-if="specificOrderSelected.health_statement"
            color="primary"
            text
            @click.stop="OPEN_SPECIFIC_HEALTH_STATEMENT_DIALOG()"
          >
            View Health Statement
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click.stop="CLOSE_SPECIFIC_ORDER_DIALOG()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <health-statement-display />
  </div>
</template>
<script>
  import { mapGetters, mapMutations } from 'vuex'
  import HealthStatementDisplay from './healthStatementDisplay.vue'
  export default {
    name: 'RegistrantOrders',
    components: {
      HealthStatementDisplay,
    },
    data () {
      return {
        dialog: false,
        orderEntries: [],
      }
    },
    computed: {
      ...mapGetters(['specificOrderDialog', 'specificOrderSelected']),
      displayableProducts () {
        const standardProductDisplays = []

        this.specificOrderSelected.club_products.forEach(product =>
          standardProductDisplays.push({
            id: product._id,
            name: product.name,
            price: product.club_price,
            productEntries: product.product_entry,
          }),
        )

        this.specificOrderSelected.products.forEach(product =>
          standardProductDisplays.push({
            id: product._id,
            name: product.name,
            price: product.default_price,
            productEntries: product.product_entry,
          }),
        )

        // We need to re-write this productEntry system.
        // What it should be is simply attaching a set of fields onto the product.
        // the order then adds the values onto the actual product; ie;
        // the product is like a 'class'... and when you make an order it makes an instance of this class and puts
        // it into the order with customisations.

        // otherwise we end up in the mess below trying to figure out which orderEntry belongs to which product entry, and which product
        // entry belongs to which product.
        console.log(this.specificOrderSelected)
        this.specificOrderSelected.order_entry.forEach(entry => {
          standardProductDisplays.forEach(productDisplay => {
            if (this.orderEntryBelongsToProduct(entry, productDisplay)) {
              const productEntryField = productDisplay.productEntries.find(
                productEntry => productEntry._id === entry.product_entry,
              )

              const augmentedOrderEntry = {
                ...entry,
                fieldName: productEntryField.name,
              }
              productDisplay.orderEntry
                ? productDisplay.orderEntry.push(augmentedOrderEntry)
                : (productDisplay.orderEntry = [augmentedOrderEntry])
            }
          })
        })

        return standardProductDisplays
      },
    },
    mounted () {
      this.orderEntries = this.specificOrderSelected.order_entry
    },
    methods: {
      ...mapMutations([
        'CLOSE_SPECIFIC_ORDER_DIALOG',
        'OPEN_SPECIFIC_HEALTH_STATEMENT_DIALOG',
      ]),
      closeDialog () {},
      orderEntryBelongsToProduct (orderEntry, product) {
        const orderEntryProductEntryId = orderEntry.product_entry
        return product.productEntries.some(
          entry => entry._id === orderEntryProductEntryId,
        )
      },
    },
  }
</script>

<style>
.configuration-tooltip {
  margin-left: 150px;
}
</style>
